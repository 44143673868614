import {Component, Injector } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {routes} from '../app-routing.module';

@Component({
  selector: 'top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent {
  links: any[] = [];
  activeLink?: string;

  constructor(router: Router, private injector: Injector) {
    this.initializeLinks();

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeLink = router.url
      }
    });
  }

  async initializeLinks() {
    for (const route of routes[0].children || []) {
      if (route.data && route.data[0] >= 0 && route.path) {
        const canActivate = await this.checkCanActivate(route);
        if (canActivate) {
          this.links.push({
            path: '/' + route.path,
            name: <string>route.title
          });
        }
      }
    }

    this.activeLink = this.links[0]?.path || '';
  }

  async checkCanActivate(route: any): Promise<boolean> {
    if (!route.canActivate || route.canActivate.length === 0) {
      return true;
    }

    for (const guard of route.canActivate) {
      const guardInstance = this.injector.get(guard);
      const result = await guardInstance.canActivate();
      if (!result) {
        return false;
      }
    }

    return true;
  }
}
