<div>
    <h1 mat-dialog-title class="label-delete-device">Confirm Delete Gateway</h1>
    <div mat-dialog-content class="as-column text-color">
        <div>
            Do you really want to delete gateway {{device.gateway_id}}?
        </div>
        <div class="as-row">
            <button mat-raised-button (click)="confirmDevice()" color="warn" > Delete</button>
            <button mat-raised-button (click)="cancelDevice()" color="white"> Cancel </button>
        </div>
    </div>
</div>
