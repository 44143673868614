import {Component, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {firstValueFrom} from 'rxjs';
import {
  ApiGatewayService,
  RegisteredDevice,
  RegisteredGateway,
  StatMessage
} from 'src/app/services/api-gateway.service';
import {delay, emitInfoMessage, InfoMessageService} from 'src/app/services/info-message.service';
import {KeycloakUserService} from 'src/app/services/keycloak-user.service';
import {KeyPairDialogComponent} from "../../key-pair-dialog/key-pair-dialog.component";
import {GatewayDialogComponent} from "../../gateway-dialog/gateway-dialog.component";
import {DeleteGatewayDialogComponent} from "../../delete-gateway-dialog/delete-gateway-dialog.component";

export interface DialogData {
  dialogType: 'update' | 'register'
  deviceData: RegisteredGateway
}

export interface DialogRemoveData {
  deviceData: RegisteredGateway
}

@Component({
  selector: 'tab-gateways',
  templateUrl: './tab-gateways.component.html',
  styleUrls: ['./tab-gateways.component.scss']
})
export class TabGatewaysComponent {
  registeredGatewaysTableColumns: string[] = ['gateway_id', 'mode', 'country_code', 'power', 'state', 'buttons']
  registeredGateways: MatTableDataSource<RegisteredGateway>;
  toUpdateGateway?: RegisteredGateway;
  selectedGateway?: RegisteredGateway;
  selectedGatewayStatsTableColumns: string[] = ['received_at', 'stats']
  selectedStatMessages: MatTableDataSource<StatMessage>;
  maxUserGateways: number = 0;

  @ViewChild('sortGateways') sortGateways = new MatSort();
  @ViewChild('sortStats') sortStats = new MatSort();

  constructor(private infoMessageService: InfoMessageService,
              private gatewayApiService: ApiGatewayService,
              private keycloakUserService: KeycloakUserService,
              public dialog: MatDialog) {
    this.registeredGateways = new MatTableDataSource();
    this.selectedStatMessages = new MatTableDataSource();
  }

  async ngOnInit() {
    // Get Devices
    this.getRegisteredGatewaysUser();
    this.maxUserGateways = this.keycloakUserService.getUserMaxGateways();
  }

  updateSelectedGatewayStats() {
    if (this.selectedGateway) this.getGatewayStats(this.selectedGateway)
  }


  async getRegisteredGatewaysUser() {
    await this.gatewayApiService.getRegisteredGateways()
      .then((data) => {
        if (data.body) {
          this.registeredGateways = new MatTableDataSource(data.body);
          this.registeredGateways.sort = this.sortGateways;
          if (data.body.length == 0) {
            emitInfoMessage("No gateways found for user", this.infoMessageService)
          } else {
            this.registeredGateways.data.forEach((gateway: RegisteredGateway) => {
              this.gatewayApiService.getGatewayState(gateway).then(r => {
                gateway.state = r.body ? r.body : undefined;
              });
            })
          }
        }
      })
      .catch((error) => {
        emitInfoMessage(error.status + ": " + error.statusText, this.infoMessageService)
      })
  }

  async broadCastAlmanacNow(gateway: RegisteredGateway) {
    await this.gatewayApiService.broadcastAlmanac(gateway)
      .then(() => {
        emitInfoMessage("Broadcasting", this.infoMessageService)
        delay(500);
      })
      .catch((error) => {
        emitInfoMessage(error.status + ": " + error.statusText, this.infoMessageService)
      })
  }

  async generateKeyPair(gateway: RegisteredGateway) {
    await this.gatewayApiService.generateKeyPair(gateway).then(r => {
      // Open dialog with existing or new made device as data
      const dialogRef = this.dialog.open(KeyPairDialogComponent, {
        data: r.body
      });
    })
      .catch((error) => {
        emitInfoMessage(error.status + ": " + error.statusText, this.infoMessageService)
      })
  }

  async deleteRegisteredGateway(toDeleteDevice: RegisteredGateway) {
    if (this.selectedGateway === toDeleteDevice) {
      this.selectedGateway = undefined;
    }
    await this.gatewayApiService.deleteRegisteredGateway(toDeleteDevice)
      .then(() => {
        emitInfoMessage("Deleted gateway", this.infoMessageService)
        delay(500);
        this.getRegisteredGatewaysUser();
      })
      .catch((error) => {
        emitInfoMessage(error.status + ": " + error.statusText, this.infoMessageService)
      })
  }

  async confirmDelete(toDeleteDevice: RegisteredGateway) {
    // Open dialog with existing or new made device as data
    const dialogRef = this.dialog.open(DeleteGatewayDialogComponent, {
      data: {deviceData: toDeleteDevice}
    });

    // Wait for first value from dialog and save
    let dialogResult = await firstValueFrom(dialogRef.afterClosed())

    // If dialog was clicked on submit (returning a true bool), i.e. not cancelled
    if (dialogResult) {
      this.deleteRegisteredGateway(toDeleteDevice)
    }
  }


  async getGatewayStats(gateway: RegisteredGateway) {
    await this.gatewayApiService.getGatewayStats(gateway)
      .then((data) => {
        if (data.body) {
          this.selectedStatMessages = new MatTableDataSource(data.body)
          this.selectedStatMessages.sort = this.sortStats;
          if (data.body.length == 0) {
            emitInfoMessage("No stats found for gateway", this.infoMessageService)
          }
        }
      })
      .catch((error) => {
        emitInfoMessage(error.status + ": " + error.statusText, this.infoMessageService)
      })
  }

  async openDialogDevice(dialogType: 'update' | 'register', toUpdateDevice?: RegisteredGateway) {
    // For register we make a new empty device
    if (!toUpdateDevice) toUpdateDevice = {gateway_id: '', mode: 'idle', power: 'low', country_code: 'NL'}

    // Open dialog with existing or new made device as data
    const dialogRef = this.dialog.open(GatewayDialogComponent, {
      data : {dialogType : dialogType, deviceData : toUpdateDevice}
    });

    // Wait for first value from dialog and save
    let dialogResult = await firstValueFrom(dialogRef.afterClosed())

    // If dialog was clicked on submit (returning a true bool), i.e. not cancelled
    if (dialogResult){
      if (dialogType == 'register'){
        this.addRegisteredGatewayDialog(toUpdateDevice)
      } else if (dialogType == 'update'){
        this.updateRegisteredDeviceDialog(toUpdateDevice)
      } else {
        emitInfoMessage("Cannot handle return values from dialog", this.infoMessageService)
      }
    }
  }

  async addRegisteredGatewayDialog(newGateway: RegisteredGateway) {
    await this.gatewayApiService.addGateway(newGateway)
      .then(() => {
        emitInfoMessage("Added gateway", this.infoMessageService)
        delay(500);
        this.getRegisteredGatewaysUser();
      })
      .catch((error) => {
        emitInfoMessage(error.status + ": " + error.statusText, this.infoMessageService)
      })
  }

  async updateRegisteredDeviceDialog(toUpdateGateway: RegisteredGateway) {
    if (!toUpdateGateway) {
      emitInfoMessage("Error in updating gateway, no gateway found to edit", this.infoMessageService)
      return
    }
    await this.gatewayApiService.updateRegisteredGateway(toUpdateGateway)
      .then(() => {
        emitInfoMessage("Updated device", this.infoMessageService)
        delay(500);
        this.getRegisteredGatewaysUser();
      })
      .catch((error) => {
        emitInfoMessage(error.status + ": " + error.statusText, this.infoMessageService)
      })
  }

}
