<div class="devices-main">
  <div class="devices-input">
    <h2 class="title-h2">Almanac Distribution System</h2>

    <div class="body-text">
      Here you can register or deregister your gateways used for the Almanac Distribution System.
      Gateways allow you to broadcast almanac to update your devices.
      Please contact the support if you want to use this functionality.
      <br>
      <br>

    </div>

    <div class="as-col">
      <button
        class="main-devices-button"
        mat-raised-button
        color="primary"
        (click)="openDialogDevice('register')"> Register new gateway
      </button>
    </div>

  </div>
  <div class="devices-output">
    <h2 class="title-h2">Registered Gateways ({{registeredGateways.data.length}}/{{maxUserGateways}})</h2>
    <section class="devices-table mat-elevation-z1">
      <table mat-table [dataSource]="registeredGateways" matSort #sorterDevices="matSort">


        <!-- gateway_id address Column -->
        <ng-container matColumnDef="gateway_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Gateway id</th>
          <td mat-cell *matCellDef="let element"> {{element.gateway_id}} </td>
        </ng-container>

        <!-- Network Column -->
        <ng-container matColumnDef="mode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Mode</th>
          <td mat-cell *matCellDef="let element" class="text-monospace"> {{element.mode}} </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="country_code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Country code</th>
          <td mat-cell *matCellDef="let element"> {{element.country_code}} </td>
        </ng-container>

        <!-- F counter Column -->
        <ng-container matColumnDef="power">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Power</th>
          <td mat-cell *matCellDef="let element"> {{element.power}} </td>
        </ng-container>
        <!-- F counter Column -->
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Connected</th>


          <td mat-cell *matCellDef="let element">  <button *ngIf="element.state.is_connected == true" class="green-button"
                    mat-icon-button>
              <mat-icon>circle</mat-icon>
          </button>
            <button *ngIf="element.state.is_connected == false"  class="red-button"
                      mat-icon-button>
                <mat-icon>circle</mat-icon>
            </button></td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="buttons">

          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element"
              (click)="$event.stopPropagation()"
              class="button-container">
            <button class="blue-button"
                    mat-icon-button
                    matTooltip="Generate key pair"
                    (click)="generateKeyPair(element)">
              <mat-icon>key</mat-icon>
            </button>
            <button class="yellow-button"
              mat-icon-button
              matTooltip="Broadcast almanac now"
              (click)="broadCastAlmanacNow(element)">
              <mat-icon>bolt</mat-icon>
            </button>
            <button
              mat-icon-button
              color="accent"
              matTooltip="Edit gateway"
              (click)="openDialogDevice('update',element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              matTooltip="Delete gateway"
              (click)="confirmDelete(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="registeredGatewaysTableColumns" class="table-header"></tr>
        <tr mat-row
            *matRowDef="let row; columns: registeredGatewaysTableColumns"
            class="element-row"
            (click)="selectedGateway = selectedGateway === row ? null : row"
            (click)="updateSelectedGatewayStats()"
            [class.expanded-row]="selectedGateway === row"></tr>
      </table>
    </section>
    <div class="no-items-found" *ngIf="registeredGateways.data.length == 0">
      No devices found / registered
    </div>

    <div>
      <h2 class="title-h2">Stats</h2>
      <div class="received-messages-text">
        <label>{{ selectedGateway ? 'Latest stats for ' + selectedGateway.gateway_id : 'Please select gateway'}}</label>
      </div>

      <section class="messages-table mat-elevation-z1">
        <table *ngIf="selectedGateway" mat-table [dataSource]="selectedStatMessages" class="mat-elevation-z1" matSort
               #sorterMessages="matSort">
          <!-- Received Column -->
          <ng-container matColumnDef="received_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Received at</th>
            <td mat-cell *matCellDef="let element"> {{element.received_at}} </td>
          </ng-container>
          <!-- Reception Date Column -->
          <ng-container matColumnDef="stats">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Metadata</th>
            <td mat-cell *matCellDef="let element"> {{element.stats.metadata | json }} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="selectedGatewayStatsTableColumns" class="messages-table-header"></tr>
          <tr mat-row *matRowDef="let row; columns: selectedGatewayStatsTableColumns" class="messages-table-row"></tr>
        </table>

      </section>
      <div class="no-items-found" *ngIf="selectedGateway != undefined && selectedStatMessages.data.length == 0">
        No messages found
      </div>
    </div>
  </div>
</div>
