<div>
    <h1 mat-dialog-title class="label-device-option">Key Pair</h1>
    <div mat-dialog-content>
      <div style="font-weight: bold;"> These are the keys use to connect the backend from the gateway. It will be displayed only once so be sure to save them.</div>
      <br> <br>
      <div ><span style="font-weight: bold;">CA certificate :</span><br>  <span [innerHTML]="formattedString(component.ca)"></span></div>
      <br>
      <div ><span style="font-weight: bold;">TLS certificate  :</span><br>  <span [innerHTML]="formattedString(component.tls_certificate)"></span></div>
      <br>
      <div ><span style="font-weight: bold;">TLS key-file :</span><br>  <span [innerHTML]="formattedString(component.tls_key)"></span></div>
    </div>
</div>
