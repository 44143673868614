import { KeycloakOnLoad } from "keycloak-js";

export const environment = {
    appname: "Dashboard",
    production: false,
    version: 'v1.4.0-beta1',
    url: 'https://dashboard-v2-test.lacuna-space.com',
    url_gatewayApi : 'https://dashboard-v2-test.lacuna-space.com/api/v0',
    keyCloakConfig: {
        url: 'https://account.lacuna.space/auth',
        realm: 'lacuna',
        clientId: 'gateway'
      },
    keyCloakInitOptions: {
        // redirectUri: 'https://dashboard-v2-test.lacuna-space.com/',
        checkLoginIframe: false,
        onLoad: <KeycloakOnLoad>'login-required',
        silentCheckSsoRedirectUri: 'https://account.lacuna.space/auth'
      }
}
