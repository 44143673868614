<div [formGroup]="deviceForm">
    <h1 mat-dialog-title class="label-device-option">{{dialogType | titlecase}}  Gateway</h1>
    <div mat-dialog-content class="form-column">
        <mat-form-field>
            <mat-label>Gateway ID</mat-label>
            <input type="text" matInput formControlName="gateway_id">
          <mat-hint>Format: 16-hex</mat-hint>
            <mat-error *ngIf="deviceForm.controls.gateway_id.errors?.['required']">Field is required</mat-error>
            <mat-error *ngIf="deviceForm.controls.gateway_id.errors?.['maxlength']">Must be 16 characters</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Mode</mat-label>
          <mat-select   formControlName="mode">
            <mat-option value="idle">Idle</mat-option>
            <mat-option value="continuous">Continuous</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="text-monospace">
          <mat-label>Power</mat-label>
          <mat-select  formControlName="power">
            <mat-option value="low">Low</mat-option>
            <mat-option value="high">High</mat-option>
          </mat-select>
        </mat-form-field>

      <mat-form-field class="text-monospace">
        <mat-label>Country code</mat-label>
        <input type="text" matInput formControlName="country_code" >
      </mat-form-field>

        <button mat-raised-button (click)="confirmDevice()"
                [mat-dialog-close]="dialogData" [disabled]="!deviceForm.valid" color="primary" > {{dialogType | titlecase}} Gateway</button>
        <button mat-raised-button (click)="cancelDevice()" color="black">Cancel</button>

    </div>
</div>
