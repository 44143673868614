import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData, TabGatewaysComponent } from '../tabs/tab-gateways/tab-gateways.component';
import { RegisteredGateway} from '../services/api-gateway.service';

@Component({
  selector: 'gateway-dialog',
  templateUrl: './gateway-dialog.component.html',
  styleUrls: ['./gateway-dialog.component.scss']
})
export class GatewayDialogComponent {
  dialogType : 'update' | 'register';
  dialogDevice : RegisteredGateway;
  deviceForm = new FormGroup({
    mode: new FormControl<string|undefined>(undefined,[Validators.required,Validators.pattern('(idle|continuous)')]),
    power: new FormControl<string|undefined>(undefined,[Validators.required,Validators.pattern('(low|high)')]),
    gateway_id: new FormControl<string|null>(null,[Validators.required,Validators.minLength(16),Validators.required,Validators.maxLength(16)]),
    country_code: new FormControl<string|null>(null,[Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<TabGatewaysComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
  ) {
    this.dialogType = dialogData.dialogType;
    this.dialogDevice = dialogData.deviceData;
    if (this.dialogType == 'update'){
      this.deviceForm.controls.mode.setValue(dialogData.deviceData.mode)
      this.deviceForm.controls.power.setValue(dialogData.deviceData.power)
      this.deviceForm.controls.gateway_id.setValue(dialogData.deviceData.gateway_id)
      this.deviceForm.controls.country_code.setValue(dialogData.deviceData.country_code)
    }
  }

  fakeNgModel(){
    // return form values to dialog data
    this.dialogDevice.mode = <string>this.deviceForm.controls.mode.value;
    this.dialogDevice.power = <string>this.deviceForm.controls.power.value;
    this.dialogDevice.gateway_id = <string>this.deviceForm.controls.gateway_id.value;
    this.dialogDevice.country_code = <string>this.deviceForm.controls.country_code.value;
  }

  confirmDevice(){
    this.fakeNgModel();
    this.dialogRef.close(true);
  }

  cancelDevice(){
    this.dialogRef.close(false);
  }
}
