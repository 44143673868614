import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {RegisteredDevice, RegisteredGateway} from '../services/api-gateway.service';
import {DialogRemoveData, TabGatewaysComponent} from "../tabs/tab-gateways/tab-gateways.component";

@Component({
  selector: 'delete-gateay-dialog',
  templateUrl: './delete-gateway-dialog.component.html',
  styleUrls: ['./delete-gateway-dialog.component.scss']
})
export class DeleteGatewayDialogComponent {
  device : RegisteredGateway;

  constructor(
    public dialogRef: MatDialogRef<TabGatewaysComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogRemoveData,
  ) {
    this.device = dialogData.deviceData;
  }

  confirmDevice(){
    this.dialogRef.close(true);
  }

  cancelDevice(){
    this.dialogRef.close(false);
  }
}
