import {Component, Injector} from '@angular/core';
import {routes} from 'src/app/app-routing.module';
import {MessageOfTheDay} from 'src/app/motd/motd.component';
import {ApiGatewayService} from 'src/app/services/api-gateway.service';
import {emitInfoMessage, InfoMessageService} from 'src/app/services/info-message.service';
import {DEFAULT_USERNAME, KeycloakUserService} from 'src/app/services/keycloak-user.service';

@Component({
  selector: 'tab-home',
  templateUrl: './tab-home.component.html',
  styleUrls: ['./tab-home.component.scss']
})
export class TabHomeComponent {
  toMakeButtons: any[] = [];
  messagesFrontendFormat: MessageOfTheDay[] = [];
  username: string = DEFAULT_USERNAME;

  constructor(private infoMessageService: InfoMessageService,
              private gatewayApiService: ApiGatewayService,
              private keycloakUserService: KeycloakUserService, private injector: Injector) {
    this.initializeRoutes();
    this.getMessagesOfTheDay();

    this.username = this.keycloakUserService.username;
    if (this.username == DEFAULT_USERNAME) {
      this.keycloakUserService.usernameService$.subscribe($event => {
        if ($event != DEFAULT_USERNAME) {
          this.username = $event;
          this.keycloakUserService.usernameService$.unsubscribe();
        }
      })
    }
  }

  async initializeRoutes() {
    for (const route of routes[0].children || []) {
      if (route.data && route.data[0] >= 0 && route.path) {
        const canActivate = await this.checkCanActivate(route);
        if (canActivate) {
          this.toMakeButtons.push({
            "path": "/" + route.path,
            "name": <string>(route.title),
            "icon": route.data[1]
          });
        }
      }
    }

  }

  async checkCanActivate(route: any): Promise<boolean> {
    if (!route.canActivate || route.canActivate.length === 0) {
      return true;
    }

    for (const guard of route.canActivate) {
      const guardInstance = this.injector.get(guard);
      const result = await guardInstance.canActivate();
      if (!result) {
        return false;
      }
    }

    return true;
  }

  async getMessagesOfTheDay() {
    await this.gatewayApiService.getMessagesOfTheDay()
      // when ok request, handle the returned messages
      .then((data) => {
        this.messagesFrontendFormat = [];
        data.body?.forEach(
          message => {
            this.messagesFrontendFormat.push(
              {severity: 'info', textTag: 'Info', textBody: message.message_text}
            )
          }
        )
      })
      // when error in request, show error to user
      .catch((error) => {
        emitInfoMessage(error.status + ": " + error.statusText, this.infoMessageService)
      })
  }

  closeMessageOfTheDay(messageToRemove: MessageOfTheDay) {
    let messagesNew: MessageOfTheDay[] = [];
    this.messagesFrontendFormat.forEach(
      message => {
        if (message != messageToRemove) {
          messagesNew.push(message)
        }
      }
    )
    this.messagesFrontendFormat = messagesNew;
  }
}
