import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData, TabDevicesComponent } from '../tabs/tab-devices/tab-devices.component';
import {GatewayKeyPair, RegisteredDevice} from '../services/api-gateway.service';
import {TabGatewaysComponent} from "../tabs/tab-gateways/tab-gateways.component";

@Component({
  selector: 'key-pair-dialog',
  templateUrl: './key-pair-dialog.component.html',
  styleUrls: ['./key-pair-dialog.component.scss']
})
export class KeyPairDialogComponent {
  component : GatewayKeyPair;

  constructor(
    public dialogRef: MatDialogRef<TabGatewaysComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GatewayKeyPair,
  ) {
    this.component = dialogData;
  }


   formattedString(data: string): string {
    return data.replace(/\n/g, '<br>');
  }

  fakeNgModel(){

  }

  confirmDevice(){
    this.fakeNgModel();
    this.dialogRef.close(true);
  }

  cancelDevice(){
    this.dialogRef.close(false);
  }
}
